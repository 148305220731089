const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function postRequestOptions(body) {
	var jwt = { token: getCookie('MyToken') };
	let requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json','Accept': 'application/json' ,'Authorization': 'Bearer ' + jwt.token },
        body: JSON.stringify(body)
	};
	return requestOptions;
}
export { postRequestOptions };

